<template>
  <div class="col-lg-4 col-md-6 col-sm-6 packages_boby_col" :class="isMobile && 'p-0'">
    <hot-offer-icon class="hot-offer-icon" :color="item.badgeColor" :text="item.badgeText" v-if="!domesticItem && isSalesDeal && !isSalesDealExpired"/>

    <div class="packages_box" v-if="domesticItem && domesticItem.dealType==='domestic'">
      <div class="imgbox">
        <img :src="imageUrl" :alt="titleDeal"
          v-bind="mainProps"
          :onerror="`this.src='${imageDomain}/assets/img/has_no_picture.jpg'`"
        >
      </div>
      <div class="packages_inner">
        <div class="packages_inner_top">
          <div class="discount_box d-flex" >
            <div class="d-block m-auto"><strong>{{Math.floor(Math.random() * 12) + 9}}%</strong> {{ $t("home.discount") }}</div>
          </div>
          <!-- <MoreDealCollapse :index="item.id" :moreItems="moreItems" class="collapse-button" v-if="moreItems && moreItems.length > 0"/> -->
          <!-- <div class="adates">Additional Dates</div> -->
        </div>
        <div class="packages_inner_middel">
          <div class="packages_inner_middel_top">
            <h2>{{ titleDeal }}</h2>
            <p><img src="assets/img/theme1/clender.png" alt="calendar"> <span dir="ltr">{{ domesticItem.from_date }} - {{ domesticItem.to_date }}</span></p>
            <a :href="device === 'desktop' ? domesticItem.order : domesticItem.mobileOrder"><div class="place btn-primary">{{ $t("home.domestic-order-button") }}</div></a>
          </div>
          <div class="packages_inner_middel_bottom d-flex justify-content-between align-items-end">
            <div class="ulist">
              <ul>
                <li><img src="assets/img/theme1/check.png" alt="check">{{$t("home.what-including-in-price")}}</li>
                <li><img src="assets/img/theme1/check.png" alt="check">{{$t("home.total-night")}}: {{ calcNight(domesticItem.to_date, domesticItem.from_date) }}
              {{ (calcNight(domesticItem.to_date, domesticItem.from_date) > 1) ? $t("home.nights") : $t("home.night") }}</li>
                <li><img src="assets/img/theme1/check.png" alt="check">{{ domesticItem.price_remark }}</li>
              </ul>
            </div>
            <div class="price">
              <h3><span class="currency-symbol">₪</span><strong>{{domesticItem.price}}</strong><br>{{$t("home.total-price-for-the-package")}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="packages_box" v-else>
      <div class="imgbox">
        <img :src="imageUrl" :alt="titleDeal"
          v-bind="mainProps"
          onerror="this.src='assets/img/has_no_picture.jpg'"
        >
      </div>
      <div class="packages_inner">
        <div class="packages_inner_top">
          <div class="discount_box d-flex" v-if="item.appliedDiscountPercent && !item.isSalesDeal">
            <div class="d-block m-auto"><strong>{{!!item.appliedDiscountPercent && Number(item.appliedDiscountPercent) > 0 ? item.appliedDiscountPercent.toFixed(0) : 0}}%</strong><br />{{ $t("home.discount")}}</div>
          </div>
        </div>
        <div class="packages_inner_middel">
          <div class="packages_inner_middel_top">
            <h2>{{ titleDeal }}</h2>
            <p><img src="assets/img/theme1/clender.png" alt="calendar"> <span dir="ltr">{{ changeDateFormat(item.departureDate) }} - {{ changeDateFormat(item.arrivalDate) }}</span></p>
            <a :href="linkDeal"><div class="place btn-primary" :class="{'disabled': isSalesDealExpired }">{{ $t("home.slide-deal.order-now") }}</div></a>
          </div>
          <div class="packages_inner_middel_bottom d-flex justify-content-between align-items-end">
            <div class="ulist">
              <ul>
                <li><img src="assets/img/theme1/check.png" alt="check">{{$t("home.what-including-in-price")}}</li>
                <li><img src="assets/img/theme1/check.png" alt="check">{{$t("home.total-night")}}: {{ item.nights }} {{ item.nights > 1 ? $t("home.nights") : $t("home.night") }}</li>
                <li><img src="assets/img/theme1/check.png" alt="check">{{ priceRemark }}</li>
              </ul>
            </div>
            <div class="price">
              <h3><span class="currency-symbol">{{convertCurrencySymbol(item.currenySymbol)}}</span><strong>{{price}}</strong><br>{{$t("home.total-price-for-the-package")}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MoreDealCollapse :index="item.id" :moreItems="moreItems" class="collapse-button" v-if="moreItems && moreItems.length > 0"/>
    <div class="collapse-button">
      <countdown
        :end-time="item.endDate" @finish="finishCountdown" v-if="!domesticItem && isWithTimer">
        <template v-slot:process="time">
          <h5>
            <b-badge variant="success" v-if="time.timeObj.d !== '0'">
              {{ $t("home.count-down-day", { day: time.timeObj.d, hour: time.timeObj.h, minute: time.timeObj.m, second: time.timeObj.s}) }}
            </b-badge>
            <b-badge variant="success" v-else-if="time.timeObj.d === '0' && time.timeObj.h !== '00'">
              {{ $t("home.count-down-hour", { hour: time.timeObj.h, minute: time.timeObj.m, second: time.timeObj.s}) }}
            </b-badge>
            <b-badge variant="success" v-else-if="time.timeObj.d === '0' && time.timeObj.h === '00' && time.timeObj.m !== '00'">
              {{ $t("home.count-down-minute", { minute: time.timeObj.m, second: time.timeObj.s}) }}
            </b-badge>
            <b-badge variant="success" v-else>
              {{ $t("home.count-down-second", { second: time.timeObj.s}) }}
            </b-badge>
          </h5>
        </template>
      </countdown>
    </div>

    <custom-tooltip :content="itineraryProps" v-if="item && item.categoryId === 'Organize_tour_packages'" :isDeal="true" class="tool-tip-button"/>

    <div class="collapse-button">
      <b-badge  variant="warning"  v-if="isSalesDeal && isSalesDealExpired">{{$t("home.count-down-expired")}}</b-badge>
    </div>
  </div>
</template>
<script>
import { BBadge } from 'bootstrap-vue';
import TemplateLesiurtabItem from './TemplateLesiurtabItem';

export default {
  name: 'LesiurTabItemTheme1',
  mixins: [TemplateLesiurtabItem],
  components: {
    BBadge,
    CustomTooltip: () => import('@/components/atoms/CustomTooltip'),
    MoreDealCollapse: () => import('@/components/atoms/moreDealCollapse/MoreDealCollapseTheme1'),
    countdown: () => import('@/components/atoms/CountDown'),
    HotOfferIcon: () => import('@/components/atoms/HotOfferIcon'),
  },
  computed: {
    imageUrl() {
      let imgLink = '';
      if (this.domesticItem && this.domesticItem.dealType === 'domestic') {
        imgLink = this.domesticItem.imgMainUrl;
      } else if (this.item.categoryId === 'Organize_tour_packages') {
        imgLink = this.item.galleryUrl[Math.floor(this.item.indexNumber % this.item.galleryUrl.length)] || this.item.imageUrl || this.getDestinationImage(this.item.destinationCode) || `${this.imageDomain}/assets/img/lesiurpackages_hotelsimg5.jpg`;
      } else if (this.item.categoryId === 'Flight_Only') {
        imgLink = this.item.destImageUrls[Math.floor(this.item.indexNumber % this.item.destImageUrls.length)] || this.item.imageUrl || this.getDestinationImage(this.item.destinationCode) || `${this.imageDomain}/assets/img/lesiurpackages_hotelsimg5.jpg`;
      } else {
        imgLink = this.item.perspectiveUrl[Math.floor(this.item.indexNumber % this.item.perspectiveUrl.length)] || this.item.imageUrl || this.item.destImageUrls[0] || this.getDestinationImage(this.item.destinationCode) || 'assets/img/lesiurpackages_hotelsimg5.jpg';
      }
      return imgLink;
    },
    titleDeal() {
      let title = '';
      if (this.domesticItem && this.domesticItem.dealType === 'domestic') {
        title = '';
      } else if (this.item.packageType !== 'F') {
        title = `${this.item.hotelName.en} , ${this.item.destinationName[this.lang]} , ${this.item.destinationCountryName[this.lang]}`;
      } else if (this.item.packageType === 'F') {
        title = `${this.item.destinationName[this.lang]} , ${this.item.destinationCountryName[this.lang]}`;
      }
      return title;
    },
    linkDeal() {
      let link = '';
      if (!this.isSalesDealExpired) {
        if (this.isLanding) {
          link = `${this.item.selectedPackageUrl}${this.odyAgentCodeParam}&utm_source=${this.landingInfo.utm_source}${this.fcAgentModeParam}`;
        } else {
          link = `${this.item.selectedPackageUrl}${this.utmSourceQuery}${this.odyAgentCodeParam}${this.fcAgentModeParam}`;
        }
      }
      return link;
    },
    price() {
      return this.item.packageType === 'F' ? this.item.discountedPriceFO?.priceByAge?.find((elm) => elm.age_from === 0 && elm.age_to === 0)?.price || 0 : this.item.discountedPrice.price_average;
    },
  },
  created() {
    // console.log(this.items);
  },
};
</script>
<style scoped>
  .hot-offer-icon {
      width: 100px;
      height: 100px;
      top: -6px;
      right: 9px;
      position: absolute;
      /* transform: rotate(90deg); */
      z-index: 2;
  }
  .packages_boby_col .packages_box {
      width: 100%;
      overflow: hidden;
      height: 330px;
      position: relative;
      background-position: center;
      background-size: cover;
      border-radius: 10px;
      margin-bottom: 24px;
  }
  .packages_boby_col .packages_box .imgbox{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    overflow: hidden;
  }
  .packages_boby_col .packages_box .imgbox img{
    width:100%;
    height: 100%;
    object-fit:cover;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    }
  .packages_boby_col .packages_box:hover .imgbox img{
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1);
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
  }
  .packages_boby_col .packages_box:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50%;
      background: rgb(0,26,45);
      background: linear-gradient(360deg, rgba(0,26,45,1) 0%, rgba(253,187,45,0) 100%);
      z-index: 1;
      width: 100%;
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
  }
  .packages_boby_col .packages_box:hover:after{
      height: 100%;
  }
  .packages_boby_col .packages_box  .packages_inner {
      position: absolute;
      z-index: 2;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
  }
  .packages_boby_col .packages_box  .packages_inner  .packages_inner_middel{ position:absolute; bottom:0; box-sizing: border-box; padding: 18px; z-index: 4; width: 100%; bottom: 0;}
  .packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top {
      margin-bottom: 54px;
  }
  .packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #fff;
}
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top p {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top p img{ margin-left:10px; }
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top button.btntext {
    background-color: #23BEDC;
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    padding: 9px 18px;
    border-radius: 4px;
    cursor: unset;
}
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_bottom .ulist ul{ margin:0; padding:0; list-style:none; }
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_bottom .ulist ul li{ font-size:12px; color:#fff; font-weight:400; margin-bottom:2px; }
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_bottom .ulist ul li img {
    min-width: 11px;
    max-width: 11px;
    margin-left: 5px;
}
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_bottom .price{ min-width:30%; max-width:30%; }
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_bottom .price h3 {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    margin: 0;
}
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_bottom .price h3 strong{ font-size:26px; font-weight:700; }
.packages_boby_col .packages_box  .packages_inner .discount_box {
    width: 166px;
    display: flex;
    background-color: #1993C8;
    height: 166px;
    position: absolute;
    left: -50px;
    top: -50px;
    border-radius: 50%;
    color: #fff;
    text-align: right;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding: 30px 29px 0 70px;
    font-size: 14px;
}
.packages_boby_col .packages_box  .packages_inner .discount_box strong {
    display: block;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
}
.packages_boby_col .packages_box  .packages_inner .discount_box:after {
    content: "";
    position: absolute;
    width: 172px;
    height: 172px;
    background-color: rgb(255 255 255 / 61%);
    left: -1px;
    bottom: -4px;
    z-index: -1;
    border-radius: 50%;
}
.packages_boby_grid .packages_boby_col:nth-of-type(6n+2) .packages_box{
    height: 540px;
}
.packages_boby_grid .packages_boby_col:nth-of-type(6n+3) .packages_box{
    height: 330px;
}
.packages_boby_grid .packages_boby_col:nth-of-type(6n+4) .packages_box{
    height: 540px;
}
.packages_boby_grid .packages_boby_col:nth-of-type(6n+5) .packages_box{
    height: 540px;
}
.packages_boby_grid .packages_boby_col:nth-of-type(6n+6) .packages_box{
    height: 330px;
}
.packages_box .packages_inner .packages_inner_middel .packages_inner_middel_top h2{
  width: 80%;
  font-size: 1.2rem;
}
.packages_box .packages_inner .packages_inner_middel .packages_inner_middel_top div.place.btn-primary{
  background-color: #23bedc;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 9px 18px;
  border-radius: 4px;
  cursor: unset;
  width: 50%;
  text-align: center;
}

@media(max-width: 1399px) {
  .packages_tabmenu .nav-tabs .nav-link {
      padding: 12px 8px;
      font-size: 13px;
  }
  .packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top h2 {
      font-size: 24px;
  }
  .packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top p {
      font-size: 15px;
  }
  .packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top p img {
      margin-left: 7px;
      width: 18px;
  }
  .packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top button.btntext {
      font-size: 13px;
      padding: 7px 12px;
  }
}

@media (max-width:767.98px){
  .packages_boby_grid .packages_boby_col:nth-of-type(2n) .packages_box {
      height: 420px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_top button.btntext {
      font-size: 10px;
      padding: 6px 8px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_top p {
      font-size: 12px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_top p img {
      margin-left: 6px;
      width: 15px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_top p {
      font-size: 12px;
      margin-bottom: 5px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_top h2 {
      font-size: 18px;
      margin-bottom: 2px;
  }
  .packages_boby_col .packages_box .packages_inner .discount_box {
      width: 140px;
      height: 140px;
      left: -39px;
      top: -53px;
      font-size: 12px;
  }
  .packages_boby_col .packages_box .packages_inner .discount_box::after {
      width: 146px;
      height: 148px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_top {
      margin-bottom: 20px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_bottom .ulist ul li {
      font-size: 9px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_bottom .ulist ul li img {
      min-width: 8px;
      max-width: 8px;
      margin-left: 3px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_bottom .price h3 strong {
      font-size: 18px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_bottom .price h3 {
      font-size: 9px;
  }
  .packages_boby_col .packages_box .packages_inner .adates {
      font-size: 10px;
  }
}
</style>
